import { Box, Button, Divider, Link, Typography } from '@mui/material'
import { CardSubstrate } from 'components'
import styles from './successCreatedOrder.module.scss'
import {ReactComponent as Success} from 'assets/icon/successIcon.svg'
import { formatMoney, selectOption} from 'shared'
import TelegramIcon from '@mui/icons-material/Telegram';
import {ReactComponent as ArrowBack} from 'assets/icon/arrowBack.svg';
import clsx from 'clsx'

export const SuccessCreatedOrder = (props: any) => {
  const { data, response, handleBack } = props;

  return (
    <Box className={styles.containerResponse}>
        <CardSubstrate styled={styles.substrate}>
          <Box className={styles.content}>
            <Box className={styles.wrapBack}>
              <Button onClick={handleBack} className={styles.buttonArrow}>
                  <ArrowBack style={{fill: '#000000'}} />
              </Button>
              <Typography className={styles.titleOrder} variant='body1'>ЗАЯВКА №{response?.number}</Typography>
            </Box>
            <Success/>
            <Typography className={styles.title}  variant='body1'>
              Отлично, заявка <span className={styles.numberOrder}>№{response?.number}</span> создана! 
              Ожидаем вас в чате для продолжения обмена
            </Typography>
            <Typography className={styles.subtitle} variant='subtitle2'>
                Занимаемся получением курса для Вас 
                и опубликуем его в специально 
                созданном чат-кассе, ожидаем
                вас по ссылке ниже
            </Typography>

            <Box className={styles.showValues}>
              <Box className={styles.wrapValues}>
                <Box >
                  <Box className={styles.city}>
                  {
                    data?.from?.currency === 'USDT' 
                    ? 
                      <Typography className={styles.textValues}>
                        {'Tether (USDT)'}
                      </Typography>
                    :
                    <>
                      <Typography className={styles.textValues}>
                        {`${data?.from?.cityAndCountry?.country},`}
                        &nbsp; 
                      </Typography>
                      <Typography className={styles.textValues}>
                        {`${data?.from?.cityAndCountry?.city}`}
                      </Typography>
                    </>
                  }
                  </Box>
                  
                </Box>
                  <Box className={styles.currency}>
                    <Typography className={clsx(styles.cityCurrencySum, styles.sumValue)}>{formatMoney(data.from?.amount)}</Typography>
                    <Typography className={styles.cityCurrencySum}>{data.from?.currency}</Typography>
                  </Box>
              </Box>
              <Divider className={styles.divider} />
              <Box className={styles.wrapValues}>
                <Box>
                  <Box className={styles.city}>
                  {
                    data?.to?.currency === 'USDT' 
                    ? 
                      <Typography className={styles.textValues}>
                        {'Tether (USDT)'}
                      </Typography>
                    :
                    <>
                      <Typography className={styles.textValues}>
                        {`${data?.to?.cityAndCountry?.country}, `}
                        &nbsp; 
                      </Typography>
                      <Typography className={styles.textValues}>
                        {`${data?.to?.cityAndCountry?.city}`}
                      </Typography>
                    </>
                  }
                  </Box>
                </Box>
                <Box className={styles.currency}>
                  <Typography className={clsx(styles.cityCurrencySum, styles.sumValue, styles.disabled)}>{"~ " +formatMoney(data.to.amount)}</Typography>
                  <Typography className={styles.cityCurrencySum}>{data.to?.currency}</Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </CardSubstrate>

        <CardSubstrate styled={styles.substrateWrapButton}>
          <Typography variant='body2'>Примечание</Typography>
          <Typography mt={'8px'} variant='subtitle2'>Указана предварительная сумму к получению. Точная сумма будет доступна в созданной чат-кассе далее.</Typography>
          <Link href={response?.link}>
            <Button 
              className={styles.buttonLinkTg}
            sx={{ "& .MuiButton-startIcon": {
                  marginRight: '32px',
                  marginLeft: '-68px'
                },
              }} 
              color='primary'variant='contained' startIcon={<TelegramIcon className={styles.tgIcon} />}>
              <Box sx={{display: 'flex', flexDirection: 'column'}}>
                ПРОДОЛЖИТЬ
                <Typography variant='subtitle2' style={{
                  color: '#B1D6FF', 
                  fontSize: '14px',
                  fontWeight: '400',
                  lineHeight: '17.05px',
                  }}>
                  Перейти в чат
                </Typography> 
                </Box>
            </Button>
          </Link>
        </CardSubstrate>
    </Box>
  )
}
