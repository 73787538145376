import { Box, Button, Divider, Tooltip, Typography } from '@mui/material';
import { CardSubstrate } from 'components';
import {ReactComponent as ArrowBack} from 'assets/icon/arrowBack.svg';
import {ReactComponent as Info} from 'assets/icon/info.svg';
import styles from './approve.module.scss';
import { formatMoney } from 'shared';
import clsx from 'clsx';

export const Approve = (props: any) => {
    const {handleBack, data, handleNext} = props;
    const { from, to } = data;

    const tooltipInfo = 'Точный адрес будет предоставлен далее в созданном чат-кассе с оператором сервиса по данной заявке'

    const arrCityFrom = from.city.split(',')
    const arrCityTo = to.city.split(',')

    return (
        <Box className={styles.approveWrapper}>
            <CardSubstrate styled={clsx(styles.substrate, styles.checkedPanel)}>

                <Box className={styles.wrapBack}>
                    <Button onClick={handleBack} className={styles.buttonArrow}>
                        <ArrowBack style={{fill: '#000000'}} />
                    </Button>

                    <Typography className={styles.title} variant='body1'>Проверьте данные</Typography>
                </Box>

                <Box className={styles.checkContainer}>

                    <Box className={styles.description}>
                        <Typography  variant='subtitle2'>
                            Внимательно проверьте данные и мы вышлем 
                            <br className={styles.transferTag}/> 
                            лучший курс обмена после получения заявки.
                        </Typography>
                    </Box>

                    <Box className={styles.fromAndTo}>
                        <Box className={styles.number}>
                            1
                        </Box>
                        <Box className={styles.option}>
                            <Typography variant='subtitle2'>
                                Отдаете деньги 
                            </Typography>
                            <Tooltip arrow placement='bottom-end' 
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: '444px',
                                            padding: '24px'
                                        }
                                    }
                                }}
                                sx={{
                                    backgroundColor: '#006FED', 
                                }} 
                                title={<Box>
                                <Typography color={'#FFFFFF'} variant='body2' >Примечание</Typography>
                                <Typography mt={'8px'} color={'#FFFFFF'} variant='subtitle2'>
                                    {tooltipInfo}
                                    </Typography>
                                    </Box>
                                }>
                                <Info/>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box className={styles.data}>
                        <Box className={styles.city}>
                            <Typography className={styles.actionFromAndTo}>Отдаю</Typography>
                            <Box className={styles.cityContent}>
                                <Typography className={styles.cityCurrencySum} >{arrCityFrom[0]}{arrCityFrom.length > 1 && ','}&nbsp; </Typography>
                                {!!arrCityFrom[1] && <Typography className={styles.cityCurrencySum} >{arrCityFrom[1]}</Typography>}
                            </Box>
                        </Box>
                        <Box className={styles.currency}>
                            <Typography className={clsx(styles.cityCurrencySum, styles.sumValue)}>{formatMoney(from.sum)}</Typography>
                            <Typography className={styles.cityCurrencySum}>{from?.currency}</Typography>
                        </Box>
                    </Box>
                    <Divider className={styles.divider}  />
                    <Box className={styles.fromAndTo}>
                        <Box className={styles.number}>
                            2
                        </Box>
                        <Box className={styles.option}>
                            <Typography fontSize={16} variant='subtitle2'>
                                Получите деньги 
                              
                            </Typography>
                            <Tooltip arrow placement='bottom-end'
                                componentsProps={{
                                    tooltip: {
                                        sx: {
                                            maxWidth: '444px',
                                            padding: '24px'
                                        }
                                    }
                                }}
                                sx={{backgroundColor: '#006FED'}} title={
                                <Box>
                                    <Typography color={'#FFFFFF'} variant='body2' >Примечание</Typography>
                                    <Typography mt={'8px'} color={'#FFFFFF'} variant='subtitle2'>
                                        {tooltipInfo}
                                    </Typography>
                                    </Box>
                                }>
                                <Info/>
                            </Tooltip>
                        </Box>
                    </Box>
                    <Box className={styles.data}>
                        <Box className={styles.city}>
                            <Typography className={styles.actionFromAndTo}>Получаю</Typography>
                            <Box className={styles.cityContent}>
                                <Typography className={styles.cityCurrencySum} >{arrCityTo[0]}{arrCityTo.length > 1 && ','}&nbsp; </Typography>
                                {!!arrCityTo[1] && <Typography className={styles.cityCurrencySum} >{arrCityTo[1]}</Typography>}
                            </Box>
                        </Box>
                        <Box className={styles.currency}>
                            <Typography className={clsx(styles.cityCurrencySum, styles.sumValue)}>{"~ " +formatMoney(to.sum)}</Typography>
                            <Typography className={styles.cityCurrencySum}>{to?.currency}</Typography>
                        </Box>
                    </Box>
                </Box>
            </CardSubstrate>
            <CardSubstrate styled={clsx(styles.substrate, styles.action)}>
                <Box className={styles.contentAction}>
                    <Typography className={styles.infoTitle} variant='body2'>Примечание</Typography>
                    <Typography className={styles.infoDesc} variant='subtitle2'>Указана предварительная сумму к <br/> получению. Точная сумма будет доступна в <br/> созданной чат-кассе далее.</Typography>

                    <Button onClick={handleNext} className={styles.buttonApprove} variant='contained'>
                        ПОДТВЕРДИТЬ
                    </Button>

                    <Button onClick={handleBack} className={styles.buttonApprove} variant='contained' color='secondary'>
                        НАЗАД
                    </Button>
                </Box>
            </CardSubstrate>
        </Box>
    )
}
