import { ToggleButton, ToggleButtonGroup } from '@mui/material'
import type { Option } from 'constants/MOCK_CONST';
import { Controller } from 'react-hook-form';
import styles from './formToggleButton.module.scss';
import clsx from 'clsx';
import { DeliveryMethods } from 'shared';
import {memo} from 'react'

export const FormToggleButton = memo((props: any) => {
    const { 
        control, 
        name, 
        array,
        setValue,
        isBgColor,
        disabledOption,
    } = props;

    const onSelect = (newValue: any, onChange: any) => {
        if(newValue !== null) {
            onChange(newValue)
            !!setValue && setValue(newValue)
        }
    }

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { value, onChange, ...fieldProps } }) => (
                    <ToggleButtonGroup
                        className={clsx(styles.toggleBar, isBgColor && styles.bgColor)}
                        sx={(name === 'to.option' || name === 'from.option') ? {width: '100%'}: {}}
                        {...fieldProps}
                        value={value}
                        exclusive
                        onChange={(event, newValue) => onSelect(newValue, onChange)}
                        aria-label="text select variable"
                    >
                        {array.map((option: Option) => (
                            <ToggleButton
                                disabled={disabledOption}
                                sx={{textTransform: 'none'}}
                                fullWidth
                                color={'primary'}
                                key={option.value}
                                value={option.value}
                                aria-label={`${option.value}`}
                            >
                                {option.name}
                            </ToggleButton>
                        ))}
                    </ToggleButtonGroup>
            )}
        />
    )
}
)