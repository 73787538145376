import { Box } from '@mui/material'
import { FormToggleButton } from 'components/formToggleButton/FormToggleButton'
import styles from './WrapAutocompleteMobile.module.scss'
import { options } from 'constants/MOCK_CONST';
import { FormAutocompleteMobile, MobileSelectPortal } from 'components';
import clsx from 'clsx';
import { memo } from 'react';

export const WrapAutocompleteMobile = memo((props: any) => {
    const {
        optionName, 
        disabledOption,
        onSetOption, 
        onClosePortal, 
        isOpenAutocomplete, 
        setIsOpenAutocomplete,
        placeholder='Выберите город',
        renderOption,
        name,
        init,
        zIndex='400',
        arrayItems,
        label,
        isFormOption,
        stateIsOpen,
        getValues, 
        setValue,
        control,
        customSupportOnChange,
        convertCurrency,
        convertSetName,
        isOptionEqualToValue,
        isChangeValue
    } = props;

    return (
       <>
            <MobileSelectPortal stateIsOpen={stateIsOpen}>
                {isFormOption && 
                    <>
                        <Box className={styles.gropeToggleType}>
                          <FormToggleButton isBgColor control={control} name={optionName} setValue={onSetOption} array={options} disabledOption={disabledOption}  />
                        </Box>
                    </>
                }
                <Box className={clsx(styles.autocompleteWrapper, isFormOption ? styles.option : styles.noOption)}>
                    <FormAutocompleteMobile
                        closePortal={onClosePortal}
                        isHiddenSwap={isOpenAutocomplete}
                        setIsHiddenSwap={setIsOpenAutocomplete}
                        getValuesForm={getValues}
                        setValueForm={setValue}
                        placeholder={placeholder}
                        renderOption={renderOption}
                        isOptionEqualToValue={isOptionEqualToValue}
                        control={control}
                        name={name}
                        zIndex={zIndex}
                        init={init}
                        array={arrayItems} 
                        label={label}
                        customSupportOnChange={customSupportOnChange}
                        convertCurrency={convertCurrency}
                        convertSetName={convertSetName}
                        isChangeValue={isChangeValue}
                    />
                </Box>
            </MobileSelectPortal>
        </>
    )
})
