import { createBreakpoints } from '@mui/system';
import { createTheme } from "@mui/material";

const breakpoints = createBreakpoints({
    values: {
      xs: 0,
      sm: 768,
      md: 1024,
      lg: 1280,
      xl: 1536,
    },
})

export const theme = createTheme({
    typography: {
      fontFamily: 'Proxima Nova Rg',
    },
    palette: {
        primary: {
            main: '#006FED',
            contrastText: '#FFFFFF'
        },
        secondary: {
            main: '#E9E9E9',
            contrastText: '#A1A1A1'
        },
        error: {
          main: '#ED0000',
          contrastText: '#FFFFFF'
        },
        success: {
          main: '#006FED',
          contrastText: '#FFFFFF'
        }
    },
    components: {
      MuiTypography: {
        styleOverrides: {
            root:{
              cursor: 'default',
              textTransform: 'none'
            },
            h1: {
                fontSize: 36,
                fontWeight: 700,
                lineHeight: '44px',
                color: '#000000'
            },
            body1: {
                fontSize: 28,
                fontWeight: 700,
                lineHeight: '34px',
                color: '#000000',
                [breakpoints.down("lg")]: {
                  fontSize: 24,
                  lineHeight: '29px',
                }
            },
            body2: {
                fontSize: 16,
                fontWeight: 700,
                lineHeight: '20px',
                color: '#000000'
            },
            subtitle1: {
                cursor: 'inherit',
                fontWeight: 700,
                fontSize: 18,
                lineHeight: '19.8px',
                color: '#000000',
                [breakpoints.down("lg")]: {
                  fontSize: 16,
                  lineHeight: '17.6px',
                }
            },
            subtitle2: {
                fontWeight: 400,
                fontSize: 15,
                lineHeight: '16.5px',
                color: '#000000',
                cursor: 'inherit',
                [breakpoints.down("lg")]: {
                  fontSize: 16,
                  fontWeight: 400,
                  lineHeight: '17.6px',
                  
                }
            },
        },
      },
      MuiToggleButton: {
        styleOverrides: {
          root: {
            fontSize: '15px',
            fontWeight: 700,
            lineHeight: '18.27px',
            color: '#000000',
            borderRadius: '10px',

            '&.MuiToggleButton-root.Mui-selected': {
              backgroundColor: '#006FED',
              color: '#FFFFFF'
            }
          }
        },
      },
      MuiToggleButtonGroup: {
        styleOverrides: {
          root: {
            height: '32px'
          }
        }
      },
      MuiButton: {
        styleOverrides: {
          root: {
            boxShadow: 'none',
            fontFamily: 'Proxima Nova Rg',
            fontWeight: 700,
            fontSize: '18px',
            lineHeight: '21.9px',
            textTransform: 'uppercase',
            width: '100%',
            height: '67px',
            '&.MuiButton-containedPrimary:hover': {
                backgroundColor: '#074d9d',
                color: '#FFFFFF',
                boxShadow: 'none'
              },
            '&.MuiButton-containedSecondary:hover': {
                backgroundColor: '#d3d3d3',
                color: '#FFFFFF',
                boxShadow: 'none'
            },
          },
        }
      },
      MuiSelect: {
        styleOverrides: {
           
            root: {
                color: '#FFFFFF',
                backgroundColor: '#006FED',
            },
          
        },
      },
      MuiMenuItem: {
        styleOverrides: {
            root: {
                fontFamily: 'Proxima Nova Rg',
                fontWeight: 400,
                fontSize: '16px',
                lineHeight: '19.49px',
                color: '#C2C2C2',
                padding: '6px 6px 6px 18px',
                // стили выпадашки селект
                // '&.Mui-selected': {
                //     color: '#FFFFFF',
                //     backgroundColor: '#006FED',
                // },
              //   '&.Mui-selected:hover': {
              //     color: '#FFFFFF',
              //     backgroundColor: '#006FED',
              // },
            }
        }
      },
      MuiList: {
        styleOverrides: {
            root: {
                paddingTop: 0,
                paddingBottom: 0,
            }
        }
      },
      MuiAutocomplete: {
        styleOverrides: {
          paper: {
            [breakpoints.down("sm")]: {
              height: '100%'
            }
          },
          option: {
            height: '63px',
          },
          popper: {
          width: '444px !important',
          border: '2px solid #006FED',
          borderTop: '0px',
          borderRadius: '0 0 8px 8px',
          transform: 'none !important',
          inset: '75px auto auto -2px !important',
            
            [breakpoints.down("sm")]: {
              width: '100% !important',
              margin: '18px 0 0 0 !important',
              border: 0,
              height: '89vh'
            }
          },
          clearIndicator: {
            color: "#006FED"
          },
          listbox: {
            padding: 0,
            fontFamily: 'Proxima Nova Rg',
            fontWeight: 400,
            fontSize: '16px',
            lineHeight: '19.49px',
            textTransform: 'none',
            [breakpoints.down("sm")]: {
              overflow: 'visible',
              height: '100%'
            },
             "& .MuiAutocomplete-option": {
                color: '#000000',
                width: '100%',
                display: 'flex',
                justifyContent: 'space-between',
                paddingTop: '0',
                paddingRight: '12px',
                paddingLeft: '14px',
                fontSize: '19.01px',
                fontWeight: 400,
                lineHeight: '23.15px',

                
                
            }
          },
          input: {
              height: '16px',
              fontSize: '19.01px',
              fontWeight: 400,
              lineHeight: '23.15px'
          }
        }
      },
      MuiTooltip: {
        styleOverrides: {
          arrow: {
            ":before": {
              backgroundColor:'#006FED',
            }
          },
          tooltip: {
            backgroundColor: '#006FED',
            color: '#FFFFFF'
          }
        },
      },
      MuiInputBase: {
        styleOverrides: {
          root: {
            '& input[type=number]': {
              'MozAppearance': 'textfield'
          },
          '& input[type=number]::-webkit-outer-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          },
          '& input[type=number]::-webkit-inner-spin-button': {
              'WebkitAppearance': 'none',
              margin: 0
          }
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& .MuiOutlinedInput-notchedOutline": {
              border: `0px`,
            },
            "&.Mui-focused": {
              "& .MuiOutlinedInput-notchedOutline": {
                border: `0px`,
              },
            }
          },
        }
      },
      MuiInputLabel: {
        styleOverrides: {
          root: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '19.49px',
            color: '#C2C2C2',
            "&.Mui-focused": {
              color: '#C2C2C2',
            }
          }
        }
      },
      MuiLink: {
        styleOverrides: {
          root: {
            cursor: 'pointer'
          }
        }
      },
      MuiAccordion: {
        styleOverrides: {
          root: {
            padding: '0 16px 0 0',
            boxShadow: 'none', 
            marginBottom: '24px',
            '&:before': {
                display: 'none',
            }
          }
        }
      },
      MuiAccordionSummary: {
        styleOverrides: {
          content: {
            margin: '24px 0 24px 0'
          },
          root: {
            padding: '0 24px'
          }
        }
      },
      MuiAccordionDetails: {
        styleOverrides: {
          root: {
            padding: '8px 24px 24px',
          }
        }
      }
    },
  })