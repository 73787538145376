import { Alert, Box,  Snackbar } from "@mui/material";
import {  SyntheticEvent, useCallback, useEffect, useMemo, useState } from "react";
import { Approve, SelectTransfer } from "./step";
import { FormProvider, useForm } from "react-hook-form";
import { joiResolver } from "@hookform/resolvers/joi";
import { DeliveryMethods, schemaValidationMain, transferSubmit } from "shared";
import { Auth } from "../../layout/auth/Auth";
import { SnackbarCloseReason } from '@mui/material/Snackbar';
import { CreatedOrder } from "./step/createdOrder";
import { baseApi } from "api";
import { useLocation } from "react-router-dom";
import { DEFAULT_FROM_CITY, DEFAULT_FROM_CURRENCY, DEFAULT_TO_CITY, DEFAULT_TO_CURRENCY } from "constants/MAIN";
import styles from './main.module.scss';

export const Main = () => {
  const getSession = sessionStorage.getItem('mainPageStep');
  const { state } = useLocation()
  const [isOpen, setIsOpen] = useState(false);
  const [isOpenError, setIsOpenError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [response, setResponse] = useState<any>(null);
  const [dataOrder, setDataOrder] = useState<any>(null)
  const [activeStep, setActiveStep] = useState(getSession ? Number(getSession) : 0);

  useEffect(()=> {
    sessionStorage.removeItem('mainPageStep')
  },[getSession])


  const handleClick = () => {
    setIsOpen(true);
  };

  const handleClose = (
    event: SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setIsOpenError(false);
    setIsOpen(false);
  };

  const memoValueInitForm = useMemo(()=> !!state ? {
    variable: state.deliveryFrom !== "crypto" && state.deliveryTo !== "crypto" ? 'cash' : 'crypto',
    rules: false,
    from: {
      city: state.cityFrom !== null ? `${state.countryFrom}, ${state.cityFrom}` : 'Tether (USDT)',
      option: state.deliveryFrom || DeliveryMethods.courier,
      sum: state.amountFrom,
      currency: state.currencyFrom
    },
    to: {
      city:  state.deliveryTo !== 'crypto' ? `${state.countryTo}, ${state.cityTo}` : 'Tether (USDT)',
      option:  state.deliveryTo || DeliveryMethods.courier,
      sum: state.amountTo,
      currency: state.currencyTo
    },
  }
  :
  {
    variable: 'cash' ,
    rules: false,
    from: {
      city: DEFAULT_FROM_CITY,
      option: 'cash',
      sum: 0,
      currency: DEFAULT_FROM_CURRENCY,
    },
    to: {
      city: DEFAULT_TO_CITY,
      option: 'cash',
      sum: 0,
      currency: DEFAULT_TO_CURRENCY,
    }
  },[state])


  const methods = useForm({
      resolver: joiResolver(schemaValidationMain),
      mode: 'all',
      defaultValues: memoValueInitForm
    })

    const {control,  trigger, handleSubmit, getValues}  = methods;

  const handleBack = useCallback(() => {
    setActiveStep((prev) => prev - 1);
  },[]);

  const handleOpenFirst = () => {
    setActiveStep(0)
  }


  const onSubmit = async (data: any) => {
    const transformDataSubmit =  transferSubmit(data);

    const response = await baseApi.postCreateTransfer(transformDataSubmit);

    if(!!response?.link && !!response?.number) {
      setDataOrder(transformDataSubmit)
      setResponse(response);
      setIsLoading(false);
    } else {
      setActiveStep(0);
      setIsOpenError(true)
    }

  } 

  
  const handleNext = useCallback(() => {
    switch (activeStep) {
      case 0:
        trigger().then((checked) => {
          if(checked) {
            setActiveStep(1)
          } else {
            console.error('isValid', checked)
          }
        });
        return;
      case 1:
        if(!!sessionStorage.getItem('tgId')) {
          handleSubmit(onSubmit)();
          setActiveStep(3);
        } else {
          setActiveStep(2);
        }
      return;
    
      default:
        setActiveStep((prev) => prev + 1);
        return;
    }
  }, [activeStep]);

  return (
    <Box className={styles.container}>
      <FormProvider {...methods} >
        <form onSubmit={handleSubmit(onSubmit)}>
          {activeStep === 0 && 
            <SelectTransfer
              handleNext={handleNext} 
              control={control} 
              isStateLink={!!state}
            />
          } 

          {activeStep === 1 && 
                <Approve data={getValues()} handleNext={handleNext}  handleBack={handleBack} />
          }

          {
            activeStep === 2 && 
            <Box sx={{display: 'flex' }}>
              <Auth handleBack={handleBack} handleSubmit={handleSubmit} onSubmit={onSubmit} handleNext={handleNext} handleOpenSuccess={handleClick}/>
            </Box>
          }

        </form>
        {
          activeStep === 3 && 
            <CreatedOrder handleBack={handleOpenFirst} isLoading={isLoading} data={dataOrder} response={response} />
        }

        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpen} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="success"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Вы успешно авторизовались!
          </Alert>
        </Snackbar>
        <Snackbar anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} open={isOpenError} autoHideDuration={4000} onClose={handleClose}>
          <Alert
            onClose={handleClose}
            severity="error"
            variant="filled"
            sx={{ width: '100%' }}
          >
            Произошла ошибка, попробуйте еще раз!<br/>
            Или сообщите в поддержку!
          </Alert>
        </Snackbar>
      </FormProvider>
    </Box>
  )
}
