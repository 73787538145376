import { Box, TextField } from '@mui/material';
import clsx from 'clsx';
import { Controller } from 'react-hook-form'
import { NumericFormat } from 'react-number-format';
import CircularProgress from '@mui/material/CircularProgress';
import styles from './formInputMoney.module.scss'


export const FormInputMoney = (props: any) => {
    const { 
        control, 
        name, 
        className, 
        variant, 
        color, 
        placeholder, 
        fullWidth, 
        InputProps, 
        inputProps, 
        handleBlur,
        disabled,
        prefix,
        customOnChange,
        onFocus,
        onBlur,
        isLoading,
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: {value, ...restField} }) => (
                <>
                {isLoading ?
                 <Box className={styles.progress}>
                    <CircularProgress size="20px" />
                 </Box>
                 :
                <NumericFormat
                    onFocus={() => onFocus && onFocus(name === 'from.sum')}
                    onBlurCapture={() => onBlur && onBlur(name === 'from.sum')}
                    value={value}
                    customInput={TextField}
                    onBlur={() => !!handleBlur ?  handleBlur() : restField.onBlur()}
                    inputProps={inputProps}
                    InputProps={InputProps}
                    fullWidth={fullWidth}
                    className={clsx(className, styles.styleInput)}
                    placeholder={placeholder}
                    variant={variant}
                    color={color}
                    disabled={disabled}
                    prefix={prefix && prefix}
                    thousandSeparator=" "
                    decimalSeparator=","
                    onValueChange={(values) => {
                        !!customOnChange && customOnChange(values.floatValue);
                        restField.onChange(values.floatValue);
                    }}
                />

                
                }
                </>
            )}
        />
    )
}