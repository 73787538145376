import { Box } from '@mui/material'
import clsx from 'clsx'
import { FormCustomAutocomplete, FormInputMoney } from 'components'
import { useFormContext } from 'react-hook-form'
import { handleError } from 'shared'
import styles from './webPanel.module.scss'
import { isOptionEqual, isOptionEqualValue, renderOptionValueLabelAutocomplete } from 'shared/helpers/mainHelpers/mainHelpers'
import { DividerSwapButton } from '../../dividerSwapButton/DividerSwapButton'

export const WebPanel = (props: any) => {
    const { control, getValues, setValue, formState:{ errors } } = useFormContext();

    const {
        errorForm,
        isFocusMoneyFrom, 
        isFocusMoneyTo, 
        fromCity, 
        isOpenAutocomplete, 
        setIsOpenAutocomplete,
        handleCurrency,
        renderOptionAutocomplete,
        arrayCountryCity,
        handleConvertCurrency,
        onFocusMoney,
        removeFocusMoney,
        handleSupportConvert,
        currencyFrom,
        arrayFromCurrency,
        toCity,
        onSwap,
        currencyTo,
        arrayToCurrency,
        isLoadingConvertFrom,
        isLoadingConvertTo,
    } = props;

  return (
    <Box className={clsx(styles.panel, (Boolean(errors.to) === true || Boolean(errors.from) === true || errorForm.length > 1 ) && styles.borderError, isFocusMoneyFrom && styles.clearBorder)}>
        <Box className={clsx(styles.borderTopPanel, styles.panelInputs, isFocusMoneyFrom && styles.focusMoneyInput)} >
            <Box className={styles.selects}>
                <FormCustomAutocomplete
                    isHiddenSwap={isOpenAutocomplete}
                    setIsHiddenSwap={setIsOpenAutocomplete}
                    getValuesForm={getValues}
                    setValueForm={setValue}
                    customOnChange={handleCurrency}
                    customSetName='from.currency'
                    getOptionDisabled={(option: string) =>
                        option === getValues('to.city')
                    }
                    isShowTitle
                    placeholder={'Выберите город'}
                    renderOption={renderOptionAutocomplete}
                    isOptionEqualToValue={isOptionEqual}
                    control={control}
                    name={'from.city'}
                    disabled={getValues('from.city') === 'Tether (USDT)'}
                    zIndex='400'
                    init={fromCity || getValues('from.city')} 
                    array={arrayCountryCity} 
                    label="Поиск офиса по городам" 
                />
            </Box>
            <Box className={styles.inputSum}>
                <FormInputMoney
                    handleBlur={handleConvertCurrency}
                    disabled={fromCity === null}
                    control={control} 
                    name={'from.sum'}
                    fullWidth 
                    placeholder={'50000'}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: "right" },
                        }
                    }}
                    onFocus={onFocusMoney}
                    onBlur={removeFocusMoney}
                    isLoading={isLoadingConvertFrom}
                />
                <FormCustomAutocomplete
                    customSupportOnChange={handleSupportConvert}
                    disabled={getValues('from.currency') === 'USDT' || fromCity === null}
                    control={control}
                    name={'from.currency'}
                    renderOption={renderOptionValueLabelAutocomplete}
                    isOptionEqualToValue={isOptionEqualValue}
                    isChangeValue={true}
                    zIndex='400'
                    init={currencyFrom}
                    array={arrayFromCurrency}
                    textAlign='end'
                    isCurrency
                    setIsHiddenSwap={setIsOpenAutocomplete}
                />
            </Box>
        </Box>

        <DividerSwapButton
            isDisabled={fromCity === null || toCity === null}
            onSwap={onSwap}
        />
        
        <Box className={
            clsx(styles.borderBottomPanel, styles.panelInputs, isFocusMoneyTo && styles.focusMoneyInput)}>
            <Box className={styles.selects}>
                <FormCustomAutocomplete
                    setIsHiddenSwap={setIsOpenAutocomplete}
                    customOnChange={handleCurrency}
                    customSetName='to.currency'
                    getOptionDisabled={(option: string) =>
                        option === getValues('from.city')
                      }
                    isShowTitle
                    control={control}
                    disabled={getValues('to.city') === 'Tether (USDT)'}
                    placeholder={'Выберите город'}
                    renderOption={renderOptionAutocomplete}
                    isOptionEqualToValue={isOptionEqual}
                    name={'to.city'}
                    zIndex='400' 
                    init={toCity || getValues('to.city')} 
                    array={arrayCountryCity} 
                    label="Поиск офиса по городам" />
            </Box>
            <Box className={styles.inputSum}>
            <FormInputMoney
                    handleBlur={()=> handleConvertCurrency(true)}
                    disabled={toCity === null}
                    control={control} 
                    name={'to.sum'}
                    fullWidth 
                    placeholder={'50000'}
                    InputProps={{
                        inputProps: {
                            style: { textAlign: "right" },
                        }
                    }}
                    onFocus={onFocusMoney}
                    onBlur={removeFocusMoney}
                    isLoading={isLoadingConvertTo}
                />
                 <FormCustomAutocomplete
                    setIsHiddenSwap={setIsOpenAutocomplete}
                    customSupportOnChange={()=> handleSupportConvert(true)}
                    disabled={getValues('to.currency') === 'USDT' || toCity === null}
                    control={control}
                    renderOption={renderOptionValueLabelAutocomplete}
                    isOptionEqualToValue={isOptionEqualValue}
                    name={'to.currency'}
                    isChangeValue={true}
                    zIndex='400' 
                    init={currencyTo} 
                    array={arrayToCurrency} 
                    isCurrency
                    textAlign='end'
                />
            </Box>
        </Box>
        
        {
            !isFocusMoneyFrom &&
            <>
                {(Boolean(errors.to) === true || Boolean(errors.from) === true || errorForm.length > 1 ) && 
                <>
                <Box className={styles.hiddenElement}/>

                 <Box className={styles.errors}>
                     {
                         handleError(errorForm.length > 1 
                         ? 
                             errorForm 
                         : 
                             errors, currencyTo)
                     }
                 </Box>
                 </>
                } 
            </>
        }
    </Box>
  )
}
