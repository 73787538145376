import { Typography } from "@mui/material";
import { CreateTransferMoney, SelectCurrency } from "api/api.interface";
import styles from './mainHelpers.module.scss'

export const renderOptionValueLabelAutocomplete = (props:any, option: SelectCurrency) => {
    return (
        <div {...props} key={option.value} >
            <p>{option.label}</p> 
            <p>{option.value}</p>
        </div>
    )
}

export const isOptionEqual = (option:string, value:string) => option === value;

export const isOptionEqualValue = (option:SelectCurrency, value:string) => option.value === value


export enum DeliveryMethods {
    office = 'office',
    courier = 'courier',
    crypto = 'crypto',
}

export const renderOptionAutocomplete = (props:any, option: string) => {
    return (
        <div {...props} key={Math.random()**2}>
            {option}
        </div>
    )
}

export const selectOption = (option: string) => {
    switch (option) {
        case DeliveryMethods.office:
            return 'офис'
        case DeliveryMethods.courier:
            return 'курьер'
        case DeliveryMethods.crypto:
            return 'CRYPTO'
    
        default:
            return option
    }
}


export const checkSubmitConvert = (data: any) => {
   
    if(
    !!data?.to?.city &&
    !!data?.to?.currency &&
    !!data?.from?.city &&
    !!data?.from?.sum &&
    !!data?.from?.currency
    ) {
        return true;
    }
    if(
    !!data?.to?.city &&
    !!data?.to?.sum &&
    !!data?.to?.currency &&
    !!data?.from?.city &&
    !!data?.from?.currency
    ) {
        return true;
    }
    return false;
}



export const variableTransferConvert = (data:any, isRevers?: boolean) => {
    if(data.to.currency ==='USDT') {
      return {
        to: {
            currency: data?.to?.currency,
            amount: data?.to?.sum,
        },
        from: {
            cityAndCountry: {
                country: data?.from?.city?.split(',')[0]?.trim(),
                city:  data?.from?.city?.split(',')[1]?.trim(),
            },
            amount: data?.from?.sum,
            currency: data?.from?.currency,
        },
        reverse: isRevers,
      }
    }
      
    if(data.from.currency ==='USDT') {
        return {
            to: {
                cityAndCountry: {
                    country: data?.to?.city?.split(',')[0]?.trim(),
                    city:  data?.to?.city?.split(',')[1]?.trim(),
                },
                currency: data?.to?.currency,
                amount: data?.to?.sum,
            },
            from: {
                amount: data?.from?.sum,
                currency: data?.from?.currency,
            },
            reverse: isRevers,
        }
    }
        
    return {
        to: {
            cityAndCountry: {
                country: data?.to?.city?.split(',')[0]?.trim(),
                city:  data?.to?.city?.split(',')[1]?.trim(),
            },
            amount: data?.to?.sum,
            currency: data?.to?.currency,
        },
        from: {
            cityAndCountry: {
                country: data?.from?.city?.split(',')[0]?.trim(),
                city:  data?.from?.city?.split(',')[1]?.trim(),
            },
            amount: data?.from?.sum,
            currency: data?.from?.currency,
        },
        reverse: isRevers,
    }
}

export const transferSubmit = (data:any):CreateTransferMoney => {
    const client = {
        telegramId: Number(sessionStorage.getItem('tgId')),
        firstName: sessionStorage.getItem('tgFirstName') as string,
        lastName: sessionStorage.getItem('tgLastName') as string | undefined,
        userName: sessionStorage.getItem('tgUsername') as string,
    }

    if(data.to.currency === 'USDT') {
      return {
            to: {
                amount: data.to.sum,
                currency: data.to.currency,
            },
            from: {
                amount: data.from.sum,
                currency: data.from.currency,
                cityAndCountry: {
                        country: data.from.city.split(',')[0].trim(),
                        city:  data.from.city.split(',')[1].trim(),
                    },
            },
            client,
        }
    }
    if(data.from.currency ==='USDT') {
        return {
            to: {
                amount: data.to.sum,
                currency: data.to.currency,
                cityAndCountry: {
                    country: data.to.city.split(',')[0].trim(),
                    city:  data.to.city.split(',')[1].trim(),
                },
            },
            from: {
                amount: data.from.sum,
                currency: data.from.currency,
            },
            client,
        };
    }
    return {
        to: {
            amount: data.to.sum,
            currency: data.to.currency,
            cityAndCountry: {
                country: data.to.city.split(',')[0].trim(),
                city:  data.to.city.split(',')[1].trim(),
            },
        },
        from: {
            amount: data.from.sum,
            currency: data.from.currency,
            cityAndCountry: {
                country: data.from.city.split(',')[0].trim(),
                city:  data.from.city.split(',')[1].trim(),
            },
        },
        client,
    }
}

export const moneyTip = (currency: string, array: any[], isMobile?: boolean) => {
    const item = array.find((el)=> el.currency === currency);

    return !!item?.minOut ?
        <Typography className={styles.text}>
            {isMobile ?  `min ${item.minOut}` : `Введите сумму от ${item.minOut}`}
        </Typography>
    :
        <Typography className={styles.text}>
            Минимальные значения пока не известны
        </Typography>
}


export const removeMargin = (sum: number) => {
   return sum - (sum * 2 / 100);
}